<template>
	<div>
		<a-spin :spinning="loading">
		<div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
			<div style="width: 800px;">
			<div class="ft20 ftw500 cl-main">支付设置</div>
			
			
			
			<div v-if="datas!=null" class="mt20">
				<a-form-model
				   ref="ruleForm"
					:model="datas"
					:label-col="{ span: 4}" 
					:wrapper-col="{ span: 20}">	
					
				   <a-alert class="mt20 mb20" message="1.服务商支付主要用于所有小程序的支付能拿到扫码支付的流水" type="info" />
					<div>
						<a-form-model-item label="服务商ID">
							<a-input v-model="datas.ser_mach_id" style="width: 300px;"/>
						</a-form-model-item>
						<a-form-model-item label="公众号ID" help="服务商绑定的公众号ID">
							<a-input v-model="datas.ser_app_id" style="width: 300px;"/>
						</a-form-model-item>
						<a-form-model-item label="API秘钥">
							<a-input v-model="datas.ser_api_auth" style="width: 300px;"/>
						</a-form-model-item>
						<a-form-model-item label="证书.cert">
							<a-textarea :rows="5" v-model="datas.ser_api_cert" style="width: 400px;"/>
						</a-form-model-item>
						<a-form-model-item label="证书.KEY">
							<a-textarea :rows="5" v-model="datas.ser_api_key" style="width: 400px;"/>
						</a-form-model-item>
					</div>
					
					<a-alert class="mt20 mb20" message="2.平台微信支付用于用户小程序续费及短信充值" type="info" />
					<div>
						<a-form-model-item label="商户ID">
							<a-input v-model="datas.mach_id" style="width: 300px;"/>
						</a-form-model-item>
						<a-form-model-item label="公众号ID" help="支付绑定的公众号ID">
							<a-input v-model="datas.app_id" style="width: 300px;"/>
						</a-form-model-item>
						<a-form-model-item label="API秘钥">
							<a-input v-model="datas.api_auth" style="width: 300px;"/>
						</a-form-model-item>
						<a-form-model-item label="证书.cert">
							<a-textarea :rows="5" v-model="datas.api_cert" style="width: 400px;"/>
						</a-form-model-item>
						<a-form-model-item label="证书.KEY">
							<a-textarea :rows="5" v-model="datas.api_key" style="width: 400px;"/>
						</a-form-model-item>
					</div>
					
					<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
					      <a-button type="primary"  @click="saveData">
					        保存设置
					      </a-button>
					</a-form-model-item>
				</a-form-model>
			</div>
			</div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				datas:null,
			}
		},
		created(){
			this.getData();
		},
		methods:{
			getData(){
				this.loading=true;
				this.$http.api('platform/admin/getSettingData',{
					key:'payment'
				}).then(res=>{
					this.loading=false;
					this.datas = res.datas;
				}).catch(res=>{
					this.loading=false;
				})
			},
			saveData(){
				this.loading=true;
				this.$http.api('platform/admin/saveSettingData',{
					key:'payment',
					datas:JSON.stringify(this.datas)
				}).then(res=>{
					this.loading=false;
					this.$message.success('保存成功');
				}).catch(res=>{
					this.loading=false;
				})
			}
		}
	}
</script>

<style>
	
</style>